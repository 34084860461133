import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Tab from './tab'


const StyledTabs = styled.div`
  width: 420px;
  max-width: 100%;
	margin: auto;
	margin-top: 3rem;
	height: 100%;
  margin-bottom: 3rem;
`

const TabContent = styled.div`
  overflow: scroll;
  width: ${props => props.maxWidth ? "100%" : "unset"};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-auto-rows: auto;
    max-height: ${props => props.minHeight || '30rem'};
    min-height: ${props => props.maxHeight || '30rem'};
    overflow: hidden;
  }
`
const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(props.children[0].props.label)
  const { minHeight, maxHeight, maxWidth } = props;
  const onClickTabItem = (tab) => {
    setActiveTab(tab);
   props.tabChanged && props.tabChanged(tab)
  }
  return (
    <StyledTabs className="tabs">
      <ol className="tab-list">
        {props.children.map((child) => {
          const { label } = child.props;

          return (
            <Tab
              activeTab={activeTab}
              key={label}
              label={label}
              onClick={onClickTabItem}
            />
          );
        })}
      </ol>
      <TabContent minHeight={minHeight} maxHeight={maxHeight} maxWidth={maxWidth} className="nopadding">
        {props.children.map((child) => {
          if (child.props.label !== activeTab) return undefined;
          return child.props.children;
        })}
      </TabContent>
    </StyledTabs>
  );
}

export default Tabs